import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useNavigate, useParams } from 'react-router-dom';
import ThanksCard from '../components/ThanksCard';
import '../global.css';
import DoneIcon from '@mui/icons-material/Done';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import CommonDialog from '../components/CommonDialog';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { pendoInitialize } from '../lib/Pendo/helper';
import { getSurveyDetail, postSurveyDetails } from '../services/surveyApi';
import { snackActions } from '../components/snackActions';
import ErrorSummary from '@govuk-react/error-summary';
import i18n from '../components/i18n';

const deviceCountDropDown = [
  {
    value: 1,
    label: 1,
  },
  {
    value: 2,
    label: 2,
  },
  {
    value: 3,
    label: 3,
  },
  {
    value: 4,
    label: 4,
  },
  {
    value: 5,
    label: 5,
  },
];

function SurveyPage({ 
  language, 
  setLanguage, 
  setOrgName 
}) {
  const { t } = useTranslation();

  const { randomId } = useParams();
  const [selectedInventoryJson, setSelectedInventoryJson] = useState();
  const [inventoryFormJson, setInventoryFormJson] = useState();
  const [deviceTypes, setDeviceTypes] = useState({});
  const [deviceInventoryRegistrationJson, setDeviceInventoryRegistrationJson] =
    useState();
  const [userInfo, setUserInfo] = useState({});
  const [surveyAdmin, setSurveyAdmin] = useState();
  const [surveyFetchError, setSurveyFetchError] = useState('');
  const [errorSummary, setErrorSummary] = useState([]);
  const [errResponseArr, setErrResponseArr] = useState([]);
  const [prefilledSurveyData, setPrefilledSurveyData] = useState();
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [openResetFormDialog, setOpenResetFormDialog] = useState(false);

  const navigate = useNavigate();

  const initializePendo = (user) => {
    pendoInitialize(
      {
        id: user.user_id,
        email: user.email,
        full_name: user.full_name,
        role: 'employee',
        language: user.language
      },
      {
        id: user.organization_id,
        country: user.country,
      }
    );
  };

  useEffect(() => {
    getSurveyDetail(randomId)
      .then((response) => {
        if (response.status) {
          response.userInfo?.language  && i18n.changeLanguage(response.userInfo.language) && setLanguage(response.userInfo.language)
          initializePendo(response.userInfo);
          response.surveyUserData && response.surveyUserData.length > 0 && setPrefilledSurveyData(response.surveyUserData);
          setDeviceInventoryRegistrationJson(response.data);
          setUserInfo(response.userInfo);
          setSurveyAdmin(response.surveyAdmin);
        } else {
          setSurveyFetchError(response.errorCode);
          setSurveyAdmin(response.surveyAdmin);
        }
      })
      .catch((err) => { });
  }, [randomId]);

  useEffect(() => {
    if (surveyAdmin && surveyAdmin.organization_name) {
      setOrgName(surveyAdmin.organization_name);
    }
  }, [surveyAdmin]);

  useEffect(() => {
    if (
      deviceInventoryRegistrationJson &&
      deviceInventoryRegistrationJson.device_types &&
      deviceInventoryRegistrationJson.device_types.length > 0 &&
      deviceInventoryRegistrationJson.dynamic_form &&
      deviceInventoryRegistrationJson.dynamic_form.length > 0
    ) {
      setSelectedInventoryJson({
        index: 0,
        device_type: deviceInventoryRegistrationJson.device_types[0].key,
      });
      if (prefilledSurveyData) {
        populatePrefilledData();
      } else {
        constructSurveyForm();
      }
    }
  }, [deviceInventoryRegistrationJson, prefilledSurveyData]);

  const populatePrefilledData = () => {
    const dummyDeviceTypes = {};
    const dummyInventoryJson = {};
    for (let outerIndex = 0; outerIndex < deviceInventoryRegistrationJson.device_types.length; outerIndex++) {
      const deviceType = deviceInventoryRegistrationJson.device_types[outerIndex];
      dummyDeviceTypes[deviceType.key] = deviceType;
      let deviceFound = false;
      for (let index = 0; index < prefilledSurveyData.length; index++) {
        const deviceKey = getDeviceCategory(prefilledSurveyData[index].device_category);
        if (deviceType.key === deviceKey) {
          deviceFound = true;
          const fieldsJson = getInventoryFormJson();
          if (deviceKey && !dummyInventoryJson.hasOwnProperty(deviceKey)) {
            dummyInventoryJson[deviceKey] = {
              detailsSubmitted: true,
              deviceCount: 1,
              haveDevice: 'Yes',
              formData: [getFormFieldValues(fieldsJson, prefilledSurveyData[index])]
            }
          } else if (deviceKey) {
            dummyInventoryJson[deviceKey].deviceCount = dummyInventoryJson[deviceKey].deviceCount++;
            dummyInventoryJson[deviceKey].formData.push(getFormFieldValues(fieldsJson, prefilledSurveyData[index]))
          }
        }
      }
      if (!deviceFound) {
        const fieldsJson = getInventoryFormJson();
        const deviceJson = {
          haveDevice: 'No',
          formData: [fieldsJson],
          deviceCount: 1,
          detailsSubmitted: true,
        };
        dummyInventoryJson[deviceType.key] = deviceJson;
      }
    }
    setDeviceTypes(dummyDeviceTypes);
    setInventoryFormJson(dummyInventoryJson);
  }

  const constructSurveyForm = () => {
    const dummyInventoryJson = {};
    deviceInventoryRegistrationJson.device_types.forEach((deviceType) => {
      const fieldsJson = getInventoryFormJson();
      const deviceJson = {
        haveDevice: '',
        formData: [fieldsJson],
        deviceCount: 1,
        detailsSubmitted: false,
      };
      dummyInventoryJson[deviceType.key] = deviceJson;
      setDeviceTypes((prevState) => ({
        ...prevState,
        [deviceType.key]: deviceType,
      }));
    });
    setInventoryFormJson(dummyInventoryJson);
  }

  const getDeviceCategory = (nativeDeviceCategory) => {
    for (let outerIndex = 0; outerIndex < deviceInventoryRegistrationJson.device_types.length; outerIndex++) {
      const deviceType = deviceInventoryRegistrationJson.device_types[outerIndex];
      const deviceLang = Object.keys(deviceType.labels);
      for (let index2 = 0; index2 < deviceLang.length; index2++) {
        if (nativeDeviceCategory === deviceType.labels[deviceLang[index2]]) {
          return deviceType.key;
        }
      }
    }
    return '';
  }

  const getFormFieldValues = (fieldsJson, prefilledFormValues) => {
    const fieldsJsonArr = Object.keys(fieldsJson);
    for (let index = 0; index < fieldsJsonArr.length; index++) {
      const fieldKey = fieldsJsonArr[index];
      if (prefilledFormValues.hasOwnProperty(fieldKey)) {
        fieldsJson[fieldKey] = { ...fieldsJson[fieldKey], value: prefilledFormValues[fieldKey] }
      }
    }
    if (prefilledFormValues.custom_fields) {
      for (let index = 0; index < prefilledFormValues.custom_fields.length; index++) {
        const customFieldData = prefilledFormValues.custom_fields[index];
        if (fieldsJson.hasOwnProperty(customFieldData.field_name) && customFieldData.custom_field_id === fieldsJson[customFieldData.field_name].asset_custom_field_id) {
          fieldsJson[customFieldData.field_name].value = customFieldData.value;
        }
      }
    }
    return fieldsJson;
  }

  const getInventoryFormJson = () => {
    const fieldsJson = {};
    deviceInventoryRegistrationJson.dynamic_form.forEach((field) => {
      switch (field.type) {
        case 'dropdown':
        case 'string':
        case 'integer':
          fieldsJson[field.name] = {
            ...field,
            value: '',
            error: false,
          };
          break;
        case 'date':
          fieldsJson[field.name] = {
            ...field,
            value: '',
            error: false,
          };
          break;
        default:
          break;
      }
    });
    return fieldsJson;
  };

  const getDeviceInventoryName = (deviceType) => {
    return deviceTypes[deviceType].labels[language];
  };

  const getDeviceInventoryStyleClass = (deviceType, indexVal) => {
    if (inventoryFormJson[deviceType.key].detailsSubmitted) {
      return true;
    } else if (selectedInventoryJson.index === indexVal) {
      return true;
    }
    return false;
  };

  const isDeviceHavingResponseErr = (deviceType) => {
    return errResponseArr.some(errResponse => (
      errResponse.device_type === deviceType
    ))
  }

  const isFieldValueHavingResponseErr = (fieldName, deviceAssetNumber) => {
    for (let index = 0; index < errResponseArr.length; index++) {
      if (errResponseArr[index].asset_number === deviceAssetNumber) {
        const errorFieldsArr = Object.keys(errResponseArr[index].error_fields);
        const elementIndex = errorFieldsArr.indexOf(fieldName)
        if (elementIndex !== -1) {
          return errResponseArr[index].error_fields[fieldName];
        } else {
          return false;
        }
      }
    }
    return false;
  }

  const getDeviceInventoryMenuCard = (deviceType, indexVal) => {
    return (
      <>
        <Card
          variant="outlined"
          key={`inventory-menu-${indexVal}`}
          className={
            getDeviceInventoryStyleClass(deviceType, indexVal) ? 'active' : ''
          }
          style={{ backgroundColor: isDeviceHavingResponseErr(deviceType.key) ? '#ffe6e6' : '#ffffff' }}
          sx={{ p: 2, mb: 2, fontSize: 14, fontWeight: 'medium' }}
          onClick={() => {
            if (inventoryFormJson[selectedInventoryJson.device_type].detailsSubmitted && inventoryFormJson[deviceType.key].detailsSubmitted) {
              setSelectedInventoryJson((prevJson) => ({
                ...prevJson,
                index: indexVal,
                device_type: deviceType.key,
              }));
            }
          }}
          data-cy={`inventory-menu-${deviceType.key}`}
        >
          <div style={{ display: 'flex', flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
            <div style={{ display: 'flex', alignItems: "center" }}>
              <span className="circlenum">
                {inventoryFormJson[deviceType.key].detailsSubmitted ? (
                  <>
                    {errResponseArr.length > 0 && isDeviceHavingResponseErr(deviceType.key) ? indexVal + 1 : < DoneIcon style={{ marginTop: '4px' }} />}
                  </>
                ) : (
                  indexVal + 1
                )}
              </span>
              {t('Inventory', {
                deviceType: getDeviceInventoryName(deviceType.key)
              })}
            </div>
            <div>
              {isDeviceHavingResponseErr(deviceType.key) && <ErrorOutlineIcon style={{ color: 'red' }} data-cy="error-icon"/>}
            </div>
          </div>
        </Card>
      </>
    );
  };

  const getDeviceInventoryMenu = () => {
    return (
      <>
        {deviceInventoryRegistrationJson.device_types.map(
          (deviceType, index) => {
            return <>{getDeviceInventoryMenuCard(deviceType, index)}</>;
          }
        )}
      </>
    );
  };

  const handleChangeFieldValue = (fieldName, index, fieldValue) => {
    const dummyFormData = [
      ...inventoryFormJson[selectedInventoryJson.device_type].formData,
    ];
    dummyFormData[index] = {
      ...dummyFormData[index],
      [fieldName]: {
        ...dummyFormData[index][fieldName],
        value: fieldValue,
      },
    };
    setInventoryFormJson((prevForm) => ({
      ...prevForm,
      [selectedInventoryJson.device_type]: {
        ...inventoryFormJson[selectedInventoryJson.device_type],
        formData: dummyFormData,
      },
    }));
  };

  const getDeviceSummary = (deviceAssetNumber) => {
    let errorSummaryString = '';
    for (let index = 0; index < errResponseArr.length; index++) {
      if (errResponseArr[index].asset_number === deviceAssetNumber && errResponseArr[index].asset_assignments) {
        const errorSummaryObj = errResponseArr[index].asset_assignments;
        const errorSummaryArr = Object.keys(errorSummaryObj);
        errorSummaryArr.forEach((errorSummary, index) => {
          if (errorSummaryObj[errorSummary]) {
            errorSummaryString = errorSummaryString.concat(errorSummaryObj[errorSummary]);
            if (index < errorSummaryArr.length - 1) {
              errorSummaryString = errorSummaryString.concat('\n');
            }
          }
        })
        break;
      }
    }
    if (errorSummaryString) {
      return (
        <div className='errorSummary'>
          {errorSummaryString}
        </div>
      )
    }
    return null;
  }

  const getInventoryFormField = (formField, index, deviceAssetNumber) => {
    switch (formField.type) {
      case 'string':
        return (
          <div className="field mb-28" key={`form-field-${index}`}>
            <TextField
              required={formField.validations.required}
              value={formField.value}
              onChange={(e) =>
                handleChangeFieldValue(formField.name, index, e.target.value)
              }
              name={formField.name}
              id={`${formField.name}-id`}
              label={formField.placeholder[language]}
              type="text"
              variant="outlined"
              fullWidth
              helperText={isFieldValueHavingResponseErr(formField.name, deviceAssetNumber) ||
                (formField.field_hint
                  ? <div style={{ whiteSpace: "pre-wrap" }}>{formField.field_hint}</div>
                  : formField.error
                    ? t('field_required_error')
                    : '')
              }
              error={Boolean(isFieldValueHavingResponseErr(formField.name, deviceAssetNumber)) || formField.error}
              data-cy={formField.name}
            />
          </div>
        );
      case 'dropdown':
        return (
          <div className="field mb-28" key={`form-field-${index}`}>
            <TextField
              required={formField.validations.required}
              value={formField.value}
              onChange={(e) =>
                handleChangeFieldValue(formField.name, index, e.target.value)
              }
              name={formField.name}
              id={`${formField.name}-id`}
              label={formField.placeholder[language]}
              type="text"
              variant="outlined"
              fullWidth
              select
              helperText={isFieldValueHavingResponseErr(formField.name, deviceAssetNumber) ||
                (formField.field_hint
                  ? <div style={{ whiteSpace: "pre-wrap" }}>{formField.field_hint}</div>
                  : formField.error
                    ? t('field_required_error')
                    : '')
              }
              error={Boolean(isFieldValueHavingResponseErr(formField.name, deviceAssetNumber)) || formField.error}
              data-cy={formField.name}
            >
              {formField.dropdown_options.map((option) => (
                <MenuItem key={option.value} value={option.value} data-cy={option.value.replaceAll(/[' ']/g, '-')}>
                  {option.display_value}
                </MenuItem>
              ))}
            </TextField>
          </div>
        );
      case 'integer':
        return (
          <div className="field mb-28" key={`form-field-${index}`}>
            <TextField
              required={formField.validations.required}
              value={formField.value}
              onChange={(e) =>
                handleChangeFieldValue(formField.name, index, e.target.value)
              }
              name={formField.name}
              id={`${formField.name}-id`}
              label={formField.placeholder[language]}
              type="number"
              variant="outlined"
              fullWidth
              helperText={isFieldValueHavingResponseErr(formField.name, deviceAssetNumber) ||
                (formField.field_hint
                  ? <div style={{ whiteSpace: "pre-wrap" }}>{formField.field_hint}</div>
                  : formField.error
                    ? t('field_required_error')
                    : '')
              }
              error={Boolean(isFieldValueHavingResponseErr(formField.name, deviceAssetNumber)) || formField.error}
              data-cy={formField.name}
            />
          </div>
        );
      case 'date':
        return (
          <div className="field mb-28" key={`form-field-${index}`}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label={formField.placeholder[language]}
                value={formField.value}
                onChange={(newValue) => {
                  handleChangeFieldValue(
                    formField.name,
                    index,
                    moment(newValue).format('YYYY-MM-DD')
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    helperText={isFieldValueHavingResponseErr(formField.name, deviceAssetNumber) ||
                      (formField.field_hint
                        ? <div style={{ whiteSpace: "pre-wrap" }}>{formField.field_hint}</div>
                        : formField.error
                          ? t('field_required_error')
                          : '')
                    }
                    error={Boolean(isFieldValueHavingResponseErr(formField.name, deviceAssetNumber)) || formField.error}
                  />
                )}
              />
            </LocalizationProvider>
          </div>
        );
      default:
        break;
    }
    return null;
  };

  const getInventoryForm = () => {
    return (
      <>
        {inventoryFormJson[selectedInventoryJson.device_type].formData.map(
          (formJson, index) => {
            const deviceAssetNumber = formJson.asset_number.value;
            return (
              <>
                <Divider textAlign="left" style={{ marginBottom: '20px' }}>
                  {getDeviceInventoryName(selectedInventoryJson.device_type)}{' '}
                  {index + 1}
                </Divider>
                {getDeviceSummary(deviceAssetNumber)}
                {Object.keys(formJson).map((formField) => {
                  return (
                    <>{getInventoryFormField(formJson[formField], index, deviceAssetNumber)}</>
                  );
                })}
              </>
            );
          }
        )}
      </>
    );
  };

  const handleDeviceCount = (event) => {
    if (
      deviceInventoryRegistrationJson.dynamic_form &&
      deviceInventoryRegistrationJson.dynamic_form.length > 0
    ) {
      const dummyFieldsArr = [];
      for (let index = 0; index < event.target.value; index++) {
        const fieldsJson = getInventoryFormJson();
        dummyFieldsArr.push(fieldsJson);
      }
      setInventoryFormJson((prevForm) => ({
        ...prevForm,
        [selectedInventoryJson.device_type]: {
          ...inventoryFormJson[selectedInventoryJson.device_type],
          deviceCount: event.target.value,
          formData: dummyFieldsArr,
        },
      }));
    }
  };

  const submitCompleteInventoryForm = async () => {
    const devicesArr = Object.keys(inventoryFormJson);
    const deviceDataArr = [];
    const deviceValidationArr = [];
    for (let index = 0; index < devicesArr.length; index++) {
      const device = devicesArr[index];
      if (inventoryFormJson[device].haveDevice === 'Yes') {
        for (let index2 = 0; index2 < inventoryFormJson[device].formData.length; index2++) {
          const deviceJson = {
            device_category: getDeviceInventoryName(device),
          };
          const customFieldsArr = [];
          const singleFormData = inventoryFormJson[device].formData[index2];
          const formFields = Object.keys(singleFormData);
          const eachDeviceJson = {
            device_type: device
          }
          for (let index3 = 0; index3 < formFields.length; index3++) {
            const singleField = singleFormData[formFields[index3]];
            if (singleField.value !== '') {
              if (singleField.field_type === 'custom_attr') {
                customFieldsArr.push({
                  custom_field_id: singleField.asset_custom_field_id,
                  field_name: singleField.name,
                  value: singleField.value.trim(),
                });
              } else {
                deviceJson[singleField.name] = singleField.value.trim();
              }
            }
            eachDeviceJson[singleField.name] = singleField.value || ''
          }
          if (customFieldsArr.length > 0) {
            deviceJson.custom_fields = customFieldsArr;
          }
          deviceDataArr.push(deviceJson);
          deviceValidationArr.push(eachDeviceJson);
        }
      }
    }
    const finalInventoryJson = {
      employee_identifier: randomId,
      data: deviceDataArr,
    };

    try {
      let surveyPostStatus = await postSurveyDetails(finalInventoryJson);
      if (surveyPostStatus.status) {
        // If all assets were saved successfully.
        navigate('/thanks', {
          replace: true,
          state: { ...surveyAdmin },
        });
      } else {
        surveyPostStatus.message &&
          snackActions.error(surveyPostStatus.message);
        if (surveyPostStatus.asset_save_status) {
          const dummyErrResponseArr = [];
          surveyPostStatus.asset_save_status.forEach((eachAsset) => {
            if (eachAsset.status === 'failure') {
              const errJson = getErrorDeviceJson(eachAsset, deviceValidationArr, deviceDataArr)
              errJson && dummyErrResponseArr.push(errJson)
              snackActions.error(
                t('error_message_content', { deviceAssetNumber: eachAsset.asset_number })
              );
            }
          });
          setErrResponseArr(dummyErrResponseArr);
          setOpenErrorDialog(true)
        }
      }
    } catch (error) {
      snackActions.error(error.message);
    }
  };

  const handleCloseErrorDialog = () => {
    setOpenErrorDialog(false)
  }

  const handleCloseResetFormDialog = () => {
    setOpenResetFormDialog(false)
  }

  const handleResetForm = async () => {
    const finalInventoryJson = {
      employee_identifier: randomId,
      data: [],
      reset: true
    };
    try {
      let surveyPostStatus = await postSurveyDetails(finalInventoryJson);
      if (surveyPostStatus.status) {
        snackActions.success('Form Reset Successfully');
        setSelectedInventoryJson({
          index: 0,
          device_type: deviceInventoryRegistrationJson.device_types[0].key,
        });
        setErrResponseArr([])
        constructSurveyForm();
        setOpenResetFormDialog(false);
      }
    } catch (error) {
      snackActions.error(error.message);
    }
  }

  const handleSingleDeviceResetForm = async () => {
    const dummyInventoryJson = { ...inventoryFormJson }
    const fieldsJson = getInventoryFormJson();
    const deviceJson = {
      haveDevice: '',
      formData: [fieldsJson],
      deviceCount: 1,
      detailsSubmitted: false,
    };
    dummyInventoryJson[selectedInventoryJson.device_type] = deviceJson;
    setInventoryFormJson(dummyInventoryJson)
    setOpenResetFormDialog(false);
  }

  const getErrorDeviceJson = (assetData, deviceValidationArr, deviceDataArr) => {
    for (let index = 0; index < deviceValidationArr.length; index++) {
      if (deviceValidationArr[index].asset_number === assetData.asset_number) {
        const deviceErrorObj = {
          device_type: deviceValidationArr[index].device_type,
          asset_number: deviceValidationArr[index].asset_number,
          error_fields: {}
        }
        const errorObjKeys = Object.keys(assetData.errors)
        for (let errorObjIndex = 0; errorObjIndex < errorObjKeys.length; errorObjIndex++) {
          const deviceErrorField = errorObjKeys[errorObjIndex];
          switch (deviceErrorField) {
            case 'asset_custom_fields':
              let deviceCustomFields;
              for (let innerIndex = 0; innerIndex < deviceDataArr.length; innerIndex++) {
                if (deviceDataArr[innerIndex].asset_number === deviceValidationArr[index].asset_number) {
                  deviceCustomFields = deviceDataArr[innerIndex].custom_fields;
                  break;
                }
              }
              if (deviceCustomFields) {
                const customFieldsArr = Object.keys(assetData.errors[deviceErrorField]);
                for (let customFieldIndex = 0; customFieldIndex < customFieldsArr.length; customFieldIndex++) {
                  const errorCustomFieldId = parseInt(customFieldsArr[customFieldIndex], 10);
                  for (let innerIndex = 0; innerIndex < deviceCustomFields.length; innerIndex++) {
                    const customFieldData = deviceCustomFields[innerIndex];
                    if (errorCustomFieldId === customFieldData.custom_field_id) {
                      deviceErrorObj.error_fields[customFieldData.field_name] = assetData.errors[deviceErrorField][customFieldsArr[customFieldIndex]]
                    }
                  }
                }
              }
              break;
            case 'asset_assignments':
              deviceErrorObj['asset_assignments'] = assetData.errors[deviceErrorField];
              break;
            default:
              deviceErrorObj.error_fields[deviceErrorField] = assetData.errors[deviceErrorField];
              break;
          }
        }
        return deviceErrorObj
      }
    }
  }

  const handleSubmit = () => {
    if (
      selectedInventoryJson.index ===
      deviceInventoryRegistrationJson.device_types.length - 1
    ) {
      if (!validateDeviceInventoryForm()) {
        submitCompleteInventoryForm();
      }
    } else {
      validateDeviceInventoryForm();
    }
  };

  const getAllAssetNumbers = () => {
    const assetNumberArr = [];
    const deviceArr = Object.keys(inventoryFormJson);
    for (let index = 0; index < deviceArr.length; index++) {
      const deviceData = inventoryFormJson[deviceArr[index]];
      if (selectedInventoryJson.device_type !== deviceArr[index] && deviceData.haveDevice === 'Yes') {
        for (let index2 = 0; index2 < deviceData.formData.length; index2++) {
          const formDataArr = deviceData.formData[index2];
          const formFieldsArr = Object.keys(formDataArr);
          for (let index3 = 0; index3 < formFieldsArr.length; index3++) {
            if (formDataArr[formFieldsArr[index3]].name === 'asset_number' && formDataArr[formFieldsArr[index3]].value) {
              assetNumberArr.push(formDataArr[formFieldsArr[index3]].value);
              break;
            }
          }
        }
      }
    }
    return assetNumberArr;
  }

  const validateDeviceInventoryForm = () => {
    let isError = false;
    if (
      inventoryFormJson[selectedInventoryJson.device_type].haveDevice === 'Yes'
    ) {
      const deviceFormData = [
        ...inventoryFormJson[selectedInventoryJson.device_type].formData,
      ];
      const allAssetNumbers = getAllAssetNumbers();
      const assetNumberArr = [];
      for (let index = 0; index < deviceFormData.length; index++) {
        const formData = deviceFormData[index];
        const formFields = Object.keys(formData);
        for (let innerIndex = 0; innerIndex < formFields.length; innerIndex++) {
          const singleField = formData[formFields[innerIndex]];
          if (singleField.validations.required && singleField.value === '') {
            singleField.error = true;
            isError = true;
          } else {
            singleField.error = false;
          }
          if (
            formFields[innerIndex] === 'asset_number' &&
            singleField.value !== ''
          ) {
            if (allAssetNumbers.includes(singleField.value) || assetNumberArr.includes(singleField.value)) {
              singleField.error = true;
              isError = true;
            } else {
              singleField.error = false;
              assetNumberArr.push(singleField.value);
            }
          }
        }
      }
      if (isError) {
        setInventoryFormJson((prevForm) => ({
          ...prevForm,
          [selectedInventoryJson.device_type]: {
            ...inventoryFormJson[selectedInventoryJson.device_type],
            formData: deviceFormData,
          },
        }));
      } else {
        setInventoryFormJson((prevForm) => ({
          ...prevForm,
          [selectedInventoryJson.device_type]: {
            ...inventoryFormJson[selectedInventoryJson.device_type],
            formData: deviceFormData,
            detailsSubmitted: true,
          },
        }));
        const currentInventoryIndex = selectedInventoryJson.index;
        if (
          deviceInventoryRegistrationJson.device_types.length - 1 >
          currentInventoryIndex
        ) {
          setSelectedInventoryJson({
            index: currentInventoryIndex + 1,
            device_type:
              deviceInventoryRegistrationJson.device_types[
                currentInventoryIndex + 1
              ].key,
          });
        }
      }
    } else {
      setInventoryFormJson((prevForm) => ({
        ...prevForm,
        [selectedInventoryJson.device_type]: {
          ...inventoryFormJson[selectedInventoryJson.device_type],
          detailsSubmitted: true,
        },
      }));
      const currentInventoryIndex = selectedInventoryJson.index;
      if (
        deviceInventoryRegistrationJson.device_types.length - 1 >
        currentInventoryIndex
      ) {
        setSelectedInventoryJson({
          index: currentInventoryIndex + 1,
          device_type:
            deviceInventoryRegistrationJson.device_types[
              currentInventoryIndex + 1
            ].key,
        });
      }
    }
    return isError;
  };

  return surveyFetchError ? (
    <ThanksCard surveyAdmin={surveyAdmin} errorMessageCode={surveyFetchError} />
  ) : (
    <div>
      <h1 data-cy="inventory-page-title">
        {userInfo && userInfo.full_name}, {t('device_type_question')}
      </h1>
      {errorSummary.length ? <ErrorSummary errors={errorSummary} /> : ''}
      {selectedInventoryJson && inventoryFormJson && (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} sx={{ mt: 5 }}>
            <Grid sx={{ pr: 6 }} item xs={8}>
              <div className="leftGrp">
                <div className="title">
                  <div data-cy={`${selectedInventoryJson.device_type}-inventory`}>
                    <span className="circlenum">
                      {selectedInventoryJson.index + 1}
                    </span>
                    {t('Inventory', {
                      deviceType: getDeviceInventoryName(selectedInventoryJson.device_type)
                    })}
                  </div>
                  {inventoryFormJson[selectedInventoryJson.device_type]
                    .haveDevice && <div>
                      <Button
                        size="large"
                        style={{ color: '#0C6CFC', borderColor: '#0C6CFC' }}
                        sx={{ fontWeight: 'bold' }}
                        variant="outlined"
                        onClick={() => setOpenResetFormDialog(true)}
                        data-cy="clear"
                      >
                        {t('Reset')}
                      </Button>
                    </div>}
                </div>
                <FormControl className="formGrp">
                  <div className="field mb-28" key="have-device">
                    <FormLabel
                      className="label"
                      id="demo-radio-buttons-group-label"
                      data-cy={`do-you-have-${selectedInventoryJson.device_type}`}
                    >
                      {t('Do you have', {
                        device_type: getDeviceInventoryName(
                          selectedInventoryJson.device_type
                        ),
                      })}{' '}
                      ?{' '}
                    </FormLabel>
                    <div className="formIn">
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue=""
                        value={
                          inventoryFormJson[selectedInventoryJson.device_type]
                            .haveDevice
                        }
                        onChange={(e) =>
                          setInventoryFormJson((prevForm) => ({
                            ...prevForm,
                            [selectedInventoryJson.device_type]: {
                              ...inventoryFormJson[
                              selectedInventoryJson.device_type
                              ],
                              haveDevice: e.target.value,
                            },
                          }))
                        }
                        name="radio-buttons-group"
                      >
                        <FormControlLabel
                          data-cy="yes"
                          value="Yes"
                          control={<Radio />}
                          label={t('Yes')}
                        />
                        <FormControlLabel
                          data-cy="no"
                          value="No"
                          control={<Radio />}
                          label={t('No')}
                        />
                      </RadioGroup>
                    </div>
                  </div>
                  {inventoryFormJson[selectedInventoryJson.device_type]
                    .haveDevice === 'Yes' && (
                      <>
                        <div className="field mb-28" key="device-count">
                          <FormLabel
                            className="label"
                            id="demo-radio-buttons-group-label"
                            data-cy="device_type_count_question"
                          >
                            {t('device_type_count_question', {
                              device_type: getDeviceInventoryName(
                                selectedInventoryJson.device_type
                              ),
                            })}
                          </FormLabel>
                          <div className="clear"></div>
                          <TextField
                            value={
                              inventoryFormJson[selectedInventoryJson.device_type]
                                .deviceCount
                            }
                            onChange={(e) => handleDeviceCount(e)}
                            label={t('Select the Number')}
                            variant="outlined"
                            fullWidth
                            select
                            data-cy="select-the-number"
                          >
                            {deviceCountDropDown.map((option) => (
                              <MenuItem key={option.value} value={option.value} data-cy={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </div>
                        {getInventoryForm()}
                      </>
                    )}
                  <div className="text-right">
                    {/* {selectedInventoryJson.index === deviceInventoryRegistrationJson.device_types.length - 1 && <Button
                      variant="outlined"
                      onClick={() => setOpenResetFormDialog(true)}
                      sx={{ fontWeight: 'bold' }}
                      size="large"
                      style={{ marginRight: '15px' }}
                    >
                      {t('Reset')}
                    </Button>} */}
                    {/* <Button
                      size="large"
                      sx={{ fontWeight: 'bold' }}
                      variant="outlined"
                      onClick={() => setOpenResetFormDialog(true)}
                    >
                      {t('Reset')}
                    </Button> */}
                    {/* {selectedInventoryJson.index === deviceInventoryRegistrationJson.device_types.length - 1 && <Button
                      variant="outlined"
                      onClick={() => setOpenResetFormDialog(true)}
                      sx={{ fontWeight: 'bold' }}
                      size="large"
                      style={{ marginRight: '15px' }}
                    >
                      {t('Reset')}
                    </Button>} */}
                    <Button
                      variant="contained"
                      size="large"
                      sx={{ fontWeight: 'bold' }}
                      className={inventoryFormJson[selectedInventoryJson.device_type]
                        .haveDevice === ''
                        ? '' : 'nextBtn'}
                      disabled={
                        inventoryFormJson[selectedInventoryJson.device_type]
                          .haveDevice === ''
                          ? Boolean(true)
                          : Boolean(false)
                      }
                      onClick={handleSubmit}
                      data-ptag={
                        selectedInventoryJson.index ===
                          deviceInventoryRegistrationJson.device_types.length - 1
                          ? 'asset-survey-attempt'
                          : 'asset-survey-next'
                      }
                      data-cy={
                        selectedInventoryJson.index ===
                          deviceInventoryRegistrationJson.device_types.length - 1
                          ? 'submit'
                          : 'next'
                      }
                    >
                      {selectedInventoryJson.index ===
                        deviceInventoryRegistrationJson.device_types.length - 1
                        ? t('Submit')
                        : t('Next')}
                    </Button>
                  </div>
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={4} className="rightTab">
              {getDeviceInventoryMenu()}
            </Grid>
          </Grid>
        </Box>
      )}
      {openErrorDialog &&
        <CommonDialog
          dialogTitle={t('submit_error_dialog_title')}
          dialogContent={t('submit_error_dialog_content')}
          openDialog={openErrorDialog}
          dialogActions={[{
            name: t('Close'),
            variant: 'outlined',
            style: { color: 'black', borderColor: '#a6a6a6' },
            onClick: handleCloseErrorDialog,
            cyAttr: 'close'
          }]}
          handleCloseDialog={handleCloseErrorDialog} 
          cyAttr='submit-error-dialog'
          cyAttrTitle='submit-error-title'/>
      }
      {openResetFormDialog &&
        <CommonDialog
          dialogTitle={t('reset_form_dialog_title')}
          dialogContent={t('reset_form_dialog_content', {
            device_type: getDeviceInventoryName(
              selectedInventoryJson.device_type
            ),
          })}
          openDialog={openResetFormDialog}
          dialogActions={[{
            name: t('Cancel'),
            variant: 'outlined',
            style: { color: 'black', borderColor: '#a6a6a6' },
            onClick: handleCloseResetFormDialog,
            cyAttr: 'close'
          }, {
            name: t('Execute'),
            onClick: handleSingleDeviceResetForm,
            variant: 'contained',
            style: { backgroundColor: '#FF2831' },
            cyAttr: 'execute'
          }]}
          handleCloseDialog={handleCloseResetFormDialog} 
          cyAttr='reset-form-dialog'
          cyAttrTitle='reset-form-title' />
      }
    </div>
  );
}

export default SurveyPage;
