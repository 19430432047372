import axios from 'axios';

const axoisInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL
});

export const getSurveyDetail = async (user_identifier) => {
    const params = {};
    user_identifier && (params.user_identifier = user_identifier);
    return new Promise((resolve, reject) => {
        axoisInstance.get("survey/getSurveyDetails", { params })
            .then(response => {
                resolve(response.data);
            })
            .catch(err => {
                reject(err);
            });
    })
};

export const getSurveyPreviewDetail = async (survey_identifier) => {
    const params = {};
    survey_identifier && (params.survey_identifier = survey_identifier);
    return new Promise((resolve, reject) => {
        axoisInstance.get("survey/getSurveyPreviewDetails", { params })
            .then(response => {
                resolve(response.data);
            })
            .catch(err => {
                reject(err);
            });
    })
};

export const postSurveyDetails = async (surveyJson) => {
    return new Promise((resolve, reject) => {
        axoisInstance.post("/survey/saveUserSurvey", surveyJson)
            .then(response => {
                resolve(response.data);
            })
            .catch(err => {
                reject(err);
            });
    })
};