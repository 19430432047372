import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

function CommonDialog(props) {
    const {
        dialogTitle,
        dialogContent,
        openDialog,
        dialogActions,
        handleCloseDialog,
        cyAttr,
        cyAttrTitle,
    } = props;
    return (
        <Dialog onClose={handleCloseDialog} open={openDialog} fullWidth data-cy={cyAttr}>
            <DialogTitle data-cy={cyAttrTitle}>{dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Typography variant="body1" gutterBottom>
                        {dialogContent}
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {dialogActions.map(action => (
                    <Button variant={action.variant} onClick={action.onClick} style={action.style} data-cy={action.cyAttr}>
                        {action.name}
                    </Button>
                ))}
            </DialogActions>
        </Dialog>
    )

}

export default CommonDialog;