const pendoInitialize = (visitor, account) => {
  try {
    if (!window['pendo'] || !window['pendo']['initialize']) {
      return;
    }
    const pendo = window['pendo'];
    pendo.initialize({ visitor, account });
  } catch (e) {
    console.info('Error while sending information to Pendo', e);
  }
};


export { pendoInitialize };
