const pendoScript = () => {
  const pendoApiKey = process.env.REACT_APP_PENDO_API_KEY;
  if (pendoApiKey) {
    const o = (window['pendo'] = window['pendo'] || {});
    o._q = o._q || [];
    const v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
    for (let w = 0, x = v.length; w < x; ++w)
      (function (m) {
        o[m] =
          o[m] ||
          function () {
            o._q[m === v[0] ? 'unshift' : 'push'](
              [m].concat([].slice.call(arguments, 0))
            );
          };
      })(v[w]);
    const script = document.createElement('script');
    script.async = true;
    script.src =
      'https://cdn.pendo.io/agent/static/' + pendoApiKey + '/pendo.js';
    const scriptDocument = document.getElementsByTagName('script')[0];
    scriptDocument.parentNode.insertBefore(script, scriptDocument);
  }
};

export default pendoScript;
