import DoneIcon from '@mui/icons-material/Done';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import CommonDialog from '../components/CommonDialog';
import i18n from '../components/i18n';
import '../global.css';
import { getSurveyPreviewDetail } from '../services/surveyApi';

const deviceCountDropDown = [
    {
        value: 1,
        label: 1
    },
    {
        value: 2,
        label: 2
    },
    {
        value: 3,
        label: 3
    },
    {
        value: 4,
        label: 4
    },
    {
        value: 5,
        label: 5
    }
]

function SurveyPreviewPage({
    language,
    setLanguage
}) {
    const { t } = useTranslation();

    const { randomId } = useParams();
    const [selectedInventoryJson, setSelectedInventoryJson] = useState();
    const [inventoryFormJson, setInventoryFormJson] = useState();
    const [deviceTypes, setDeviceTypes] = useState({});
    const [deviceInventoryRegistrationJson, setDeviceInventoryRegistrationJson] = useState();
    const [userInfo, setUserInfo] = useState({})
    const [openResetFormDialog, setOpenResetFormDialog] = useState(false);

    useEffect(() => {
        getSurveyPreviewDetail(randomId).then(response => {
            setDeviceInventoryRegistrationJson(response.data);
            setUserInfo(response.userInfo)
            response.userInfo.language  && i18n.changeLanguage(response.userInfo.language) && setLanguage(response.userInfo.language)
        }).catch(err => {
        });
    }, [randomId])

    useEffect(() => {
        if (deviceInventoryRegistrationJson &&
            deviceInventoryRegistrationJson.device_types &&
            deviceInventoryRegistrationJson.device_types.length > 0 &&
            deviceInventoryRegistrationJson.dynamic_form &&
            deviceInventoryRegistrationJson.dynamic_form.length > 0) {
            setSelectedInventoryJson({
                index: 0,
                device_type: deviceInventoryRegistrationJson.device_types[0].key
            });
            const dummyInventoryJson = {};
            deviceInventoryRegistrationJson.device_types.forEach(deviceType => {
                const fieldsJson = getInventoryFormJson();
                const deviceJson = {
                    haveDevice: '',
                    formData: [fieldsJson],
                    deviceCount: 1,
                    detailsSubmitted: false
                };
                dummyInventoryJson[deviceType.key] = deviceJson;
                setDeviceTypes((prevState) => ({ ...prevState, [deviceType.key]: deviceType }))
            })
            setInventoryFormJson(dummyInventoryJson);
        }
    }, [deviceInventoryRegistrationJson]);

    const getInventoryFormJson = () => {
        const fieldsJson = {};
        deviceInventoryRegistrationJson.dynamic_form.forEach(field => {
            switch (field.type) {
                case 'dropdown':
                case 'string':
                case 'integer':
                    fieldsJson[field.name] = {
                        ...field,
                        value: '',
                        error: false
                    }
                    break;
                case 'date':
                    fieldsJson[field.name] = {
                        ...field,
                        value: moment(new Date()).format('YYYY-MM-DD'),
                        error: false
                    }
                    break;
                default: break;
            }
        })
        return fieldsJson;
    }

    const getDeviceInventoryName = (deviceType) => {
        return deviceTypes[deviceType].labels[language];
    }

    const getDeviceInventoryStyleClass = (deviceType, indexVal) => {
        if (inventoryFormJson[deviceType.key].detailsSubmitted) {
            return true;
        } else if (selectedInventoryJson.index === indexVal) {
            return true;
        }
        return false;
    }

    const handleSingleDeviceResetForm = async () => {
        const dummyInventoryJson = { ...inventoryFormJson }
        const fieldsJson = getInventoryFormJson();
        const deviceJson = {
            haveDevice: '',
            formData: [fieldsJson],
            deviceCount: 1,
            detailsSubmitted: false,
        };
        dummyInventoryJson[selectedInventoryJson.device_type] = deviceJson;
        setInventoryFormJson(dummyInventoryJson)
        setOpenResetFormDialog(false);
    }

    const handleCloseResetFormDialog = () => {
        setOpenResetFormDialog(false)
    }

    const getDeviceInventoryMenuCard = (deviceType, indexVal) => {
        return (
            <Card variant="outlined"
                key={`inventory-menu-${indexVal}`}
                className={getDeviceInventoryStyleClass(deviceType, indexVal) ? 'active' : ''}
                sx={{ p: 2, mb: 2, fontSize: 14, fontWeight: 'medium' }}
                onClick={() => {
                    if (inventoryFormJson[deviceType.key].detailsSubmitted) {
                        setSelectedInventoryJson(prevJson => ({ ...prevJson, index: indexVal, device_type: deviceType.key }))
                    }
                }}
            >
                <span className="circlenum">{inventoryFormJson[deviceType.key].detailsSubmitted ? <DoneIcon style={{ marginTop: '4px' }} /> : indexVal + 1}</span>{t('Inventory', {
                    deviceType : getDeviceInventoryName(deviceType.key)
                })}
            </Card>
        )
    }

    const getDeviceInventoryMenu = () => {
        return (
            <>
                {deviceInventoryRegistrationJson.device_types.map((deviceType, index) => {
                    return (
                        <>
                            {getDeviceInventoryMenuCard(deviceType, index)}
                        </>
                    )
                })}
            </>
        )
    }

    const handleChangeFieldValue = (fieldName, index, fieldValue) => {
        const dummyFormData = [...inventoryFormJson[selectedInventoryJson.device_type].formData];
        dummyFormData[index] = { ...dummyFormData[index], [fieldName]: { ...dummyFormData[index][fieldName], value: fieldValue } }
        setInventoryFormJson(prevForm => ({ ...prevForm, [selectedInventoryJson.device_type]: { ...inventoryFormJson[selectedInventoryJson.device_type], formData: dummyFormData } }));
    }

    const getInventoryFormField = (formField, index) => {
        switch (formField.type) {
            case 'string':
                return (
                    <div className='field mb-28' key={`form-field-${index}`}>
                        <TextField
                            required={formField.validations.required}
                            value={formField.value}
                            onChange={e => handleChangeFieldValue(formField.name, index, e.target.value)}
                            name={formField.name}
                            id={`${formField.name}-id`}
                            label={formField.placeholder[language]}
                            type="text"
                            variant="outlined"
                            fullWidth
                            helperText={formField.field_hint ? <div style={{ whiteSpace: "pre-wrap" }}>{formField.field_hint}</div> : (formField.error ? 'This field is required' : '')}
                            error={formField.error}
                        />
                    </div>
                )
            case 'dropdown':
                return (
                    <div className='field mb-28' key={`form-field-${index}`}>
                        <TextField
                            required={formField.validations.required}
                            value={formField.value}
                            onChange={e => handleChangeFieldValue(formField.name, index, e.target.value)}
                            name={formField.name}
                            id={`${formField.name}-id`}
                            label={formField.placeholder[language]}
                            type="text"
                            variant="outlined"
                            fullWidth
                            select
                            helperText={formField.field_hint ? formField.field_hint : (formField.error ? 'This field is required' : '')}
                            error={formField.error}
                        >
                            {formField.dropdown_options.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.display_value}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                );
            case 'integer':
                return (
                    <div className='field mb-28' key={`form-field-${index}`}>
                        <TextField
                            required={formField.validations.required}
                            value={formField.value}
                            onChange={e => handleChangeFieldValue(formField.name, index, e.target.value)}
                            name={formField.name}
                            id={`${formField.name}-id`}
                            label={formField.placeholder[language]}
                            type="number"
                            variant="outlined"
                            fullWidth
                            helperText={formField.field_hint ? formField.field_hint : (formField.error ? 'This field is required' : '')}
                            error={formField.error}
                        />
                    </div>
                )
            case 'date':
                return (
                    <div className='field mb-28' key={`form-field-${index}`}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label={formField.placeholder[language]}
                                value={formField.value}
                                onChange={(newValue) => {
                                    handleChangeFieldValue(formField.name, index, moment(newValue).format('YYYY-MM-DD'))
                                }}
                                renderInput={(params) =>
                                    <TextField {...params}
                                        fullWidth
                                        helperText={formField.field_hint ? formField.field_hint : (formField.error ? 'This field is required' : '')}
                                        error={formField.error}
                                    />}
                            />
                        </LocalizationProvider>
                    </div>
                )
            default: break;
        }
        return null;
    }

    const getInventoryForm = () => {
        return (
            <>
                {inventoryFormJson[selectedInventoryJson.device_type].formData.map((formJson, index) => {
                    return <>
                        <Divider textAlign="left" style={{ marginBottom: '20px' }} >{getDeviceInventoryName(selectedInventoryJson.device_type)} {index + 1}</Divider>
                        {
                            Object.keys(formJson).map(formField => {
                                return (
                                    <>
                                        {getInventoryFormField(formJson[formField], index)}
                                    </>
                                )
                            })
                        }

                    </>
                })}
            </>
        )
    }

    const handleDeviceCount = (event) => {
        if (deviceInventoryRegistrationJson.dynamic_form &&
            deviceInventoryRegistrationJson.dynamic_form.length > 0) {
            const dummyFieldsArr = [];
            for (let index = 0; index < event.target.value; index++) {
                const fieldsJson = getInventoryFormJson();
                dummyFieldsArr.push(fieldsJson);
            }
            setInventoryFormJson(prevForm => ({
                ...prevForm, [selectedInventoryJson.device_type]: {
                    ...inventoryFormJson[selectedInventoryJson.device_type],
                    deviceCount: event.target.value,
                    formData: dummyFieldsArr
                }
            }))
        }
    }


    const handleSubmit = () => {
        if (selectedInventoryJson.index === deviceInventoryRegistrationJson.device_types.length - 1) {
            if (!validateDeviceInventoryForm()) {
                // submitCompleteInventoryForm();
            }
        } else {
            validateDeviceInventoryForm();
        }
    }

    const validateDeviceInventoryForm = () => {
        let isError = false;
        if (inventoryFormJson[selectedInventoryJson.device_type].haveDevice === 'Yes') {
            const deviceFormData = [...inventoryFormJson[selectedInventoryJson.device_type].formData];
            const assetNumberArr = [];
            for (let index = 0; index < deviceFormData.length; index++) {
                const formData = deviceFormData[index];
                const formFields = Object.keys(formData);
                for (let innerIndex = 0; innerIndex < formFields.length; innerIndex++) {
                    const singleField = formData[formFields[innerIndex]];
                    if (singleField.validations.required && singleField.value === '') {
                        singleField.error = true;
                        isError = true;
                    } else {
                        singleField.error = false;
                    }
                    if (formFields[innerIndex] === 'asset_number' && singleField.value !== '') {
                        if (assetNumberArr.includes(singleField.value)) {
                            singleField.error = true;
                            isError = true;
                        } else {
                            singleField.error = false;
                            assetNumberArr.push(singleField.value);
                        }
                    }
                }
            }
            // No validation Needed when Preview
            if (false) { // if (isError) {
                setInventoryFormJson(prevForm => ({
                    ...prevForm, [selectedInventoryJson.device_type]: {
                        ...inventoryFormJson[selectedInventoryJson.device_type],
                        formData: deviceFormData
                    }
                }));
            } else {
                setInventoryFormJson(prevForm => ({
                    ...prevForm, [selectedInventoryJson.device_type]: {
                        ...inventoryFormJson[selectedInventoryJson.device_type],
                        formData: deviceFormData,
                        detailsSubmitted: true
                    }
                }));
                const currentInventoryIndex = selectedInventoryJson.index;
                if (deviceInventoryRegistrationJson.device_types.length - 1 > currentInventoryIndex) {
                    setSelectedInventoryJson({
                        index: currentInventoryIndex + 1,
                        device_type: deviceInventoryRegistrationJson.device_types[currentInventoryIndex + 1].key
                    });
                }
            }
        } else {
            setInventoryFormJson(prevForm => ({ ...prevForm, [selectedInventoryJson.device_type]: { ...inventoryFormJson[selectedInventoryJson.device_type], detailsSubmitted: true } }))
            const currentInventoryIndex = selectedInventoryJson.index;
            if (deviceInventoryRegistrationJson.device_types.length - 1 > currentInventoryIndex) {
                setSelectedInventoryJson({
                    index: currentInventoryIndex + 1,
                    device_type: deviceInventoryRegistrationJson.device_types[currentInventoryIndex + 1].key
                });
            }
        }
        return isError;
    }

    return (
        <div>
            <h1>{userInfo && userInfo.full_name} , {t('device_type_question')}</h1>
            {selectedInventoryJson && inventoryFormJson &&
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2} sx={{ mt: 5 }}>
                        <Grid sx={{ pr: 6 }} item xs={8}>
                            <div className="leftGrp">
                                <div className='title'>
                                    <div>
                                        <span className='circlenum'>{selectedInventoryJson.index + 1}</span>
                                        {t('Inventory', {
                                            deviceType: getDeviceInventoryName(selectedInventoryJson.device_type)
                                        })}
                                    </div>
                                    {inventoryFormJson[selectedInventoryJson.device_type]
                                        .haveDevice && <div>
                                            <Button
                                                size="large"
                                                style={{ color: '#0C6CFC', borderColor: '#0C6CFC' }}
                                                sx={{ fontWeight: 'bold' }}
                                                variant="outlined"
                                                onClick={() => setOpenResetFormDialog(true)}
                                            >
                                                {t('Reset')}
                                            </Button>
                                        </div>
                                    }
                                </div>
                                <FormControl className='formGrp'>
                                    <div className='field mb-28' key='have-device'>
                                        <FormLabel className='label' id="demo-radio-buttons-group-label">{t("Do you have", { device_type: getDeviceInventoryName(selectedInventoryJson.device_type) })}? </FormLabel>
                                        <div className='formIn'>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                defaultValue=""
                                                value={inventoryFormJson[selectedInventoryJson.device_type].haveDevice}
                                                onChange={(e) =>
                                                    setInventoryFormJson(prevForm => ({ ...prevForm, [selectedInventoryJson.device_type]: { ...inventoryFormJson[selectedInventoryJson.device_type], haveDevice: e.target.value } }))}
                                                name="radio-buttons-group"
                                            >
                                                <FormControlLabel value="Yes" control={<Radio />} label={t("Yes")} />
                                                <FormControlLabel value="No" control={<Radio />} label={t("No")} />
                                            </RadioGroup>
                                        </div>
                                    </div>
                                    {inventoryFormJson[selectedInventoryJson.device_type].haveDevice === 'Yes' && <>
                                        <div className='field mb-28' key='device-count'>
                                            <FormLabel className='label' id="demo-radio-buttons-group-label">{t("device_type_count_question", { device_type: getDeviceInventoryName(selectedInventoryJson.device_type) })}</FormLabel>
                                            <div className='clear'></div>
                                            <TextField
                                                value={inventoryFormJson[selectedInventoryJson.device_type].deviceCount}
                                                onChange={(e) => handleDeviceCount(e)}
                                                label={t('Select the Number')}
                                                variant="outlined"
                                                fullWidth
                                                select
                                            >
                                                {deviceCountDropDown.map(option => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </div>
                                        {getInventoryForm(selectedInventoryJson.device_type)}
                                    </>
                                    }
                                    <div className="text-right">
                                        <Button
                                            variant="contained"
                                            size="large"
                                            sx={{ fontWeight: 'bold' }}
                                            className={inventoryFormJson[selectedInventoryJson.device_type].haveDevice === '' || selectedInventoryJson.index === deviceInventoryRegistrationJson.device_types.length - 1 ? '' : 'nextBtn'}
                                            disabled={inventoryFormJson[selectedInventoryJson.device_type].haveDevice === '' || selectedInventoryJson.index === deviceInventoryRegistrationJson.device_types.length - 1 ? Boolean(true) : Boolean(false)}
                                            onClick={handleSubmit}>
                                            {selectedInventoryJson.index === deviceInventoryRegistrationJson.device_types.length - 1 ? t('Submit') : t('Next')}
                                        </Button>
                                    </div>
                                </FormControl>
                            </div>
                        </Grid>
                        <Grid item xs={4} className="rightTab">
                            {getDeviceInventoryMenu()}
                        </Grid>
                    </Grid>
                </Box >
            }
            {openResetFormDialog &&
                <CommonDialog
                    dialogTitle={t('reset_form_dialog_title')}
                    dialogContent={t('reset_form_dialog_content', {
                        device_type: getDeviceInventoryName(
                            selectedInventoryJson.device_type
                        ),
                    })}
                    openDialog={openResetFormDialog}
                    dialogActions={[{
                        name: t('Cancel'),
                        variant: 'outlined',
                        style: { color: 'black', borderColor: '#a6a6a6' },
                        onClick: handleCloseResetFormDialog
                    }, {
                        name: t('Execute'),
                        onClick: handleSingleDeviceResetForm,
                        variant: 'contained',
                        style: { backgroundColor: '#FF2831' }
                    }]}
                    handleCloseDialog={handleCloseResetFormDialog} />
            }
        </div >
    );
}

export default SurveyPreviewPage;


//todo
//1. /survey/randomstring then call the api and pass random string
//2.getSurveyDetail api and pass random string.