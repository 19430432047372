import Grid from '@mui/material/Grid';
import ThanksCard from '../components/ThanksCard';
import { useLocation } from 'react-router-dom';

function ThanksPage(props) {
    const { state } = useLocation();
    return (
        <Grid container>
            <Grid item xs={12} data-cy="thanks-dialog">
                <ThanksCard surveyAdmin={state ? state.surveyAdmin : null} />
            </Grid>
        </Grid>
    );
}

export default ThanksPage;
