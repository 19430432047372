import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";


// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {
        translation: {
            "Submit": "Submit",
            "Reset": "RESET",
            "Execute": "RESET",
            "Cancel": "Cancel",
            "Close": "Close",
            "Next": "Next",
            "Yes": "Yes",
            "No": "No",
            "Select the Number": "Select the Number",
            "Do you have": "Do you have a {{device_type}}",
            "Inventory": "{{deviceType}}",
            "device_type_question": "you have been requested to complete an invetory survey.",
            "device_type_count_question": "How many {{device_type}}(s) do you have?",
            SURVEY_EXPIRED_OR_TERMINATED: 'This survey has been terminated or expired',
            SURVEY_NOT_FOUND: 'This seems to be a invalid survey request',
            SURVEY_ALREADY_ANSWERED: 'You have already taken this survey',
            SURVEY_FILLED_THANKS: 'Thank You for filling out the survey.',
            "thanks_page_survey_thanks": "",
            "thanks_page_surveyAdminInfo": "Contact {{surveyAdminFullName}} at {{surveyAdminEmail}} for any queries.",
            "field_required_error": "This field is required",
            "submit_error_dialog_title": "There was an error in your input.",
            "submit_error_dialog_content": "Response could not be sent due to incorrect information entered. Please check your response and correct it.",
            "reset_form_dialog_title": "Reset Responses",
            "reset_form_dialog_content": `This would clear your responses from the Desktop Computer section.`,
            "error_message_content": "Asset {{deviceAssetNumber}} failed to save"
        }
    },
    ja: {
        translation: {
            "Submit": "送信",
            "Reset": "クリア",
            "Execute": "実行する",
            "Cancel": "閉じる",
            "Close": "閉じる",
            "Next": "次へ",
            "Yes": "はい",
            "No": "いいえ",
            "Select the Number": "番号を選択してください",
            "Do you have": "{{device_type}} を所有していますか",
            "Inventory": "{{deviceType}} 在庫",
            "device_type_question": "お持ちのデバイスを教えてください。",
            "device_type_count_question": "いくつの {{device_type}} を所有していますか？",
            SURVEY_EXPIRED_OR_TERMINATED: "アンケートの回答期限が切れています",
            SURVEY_NOT_FOUND: 'これは無効な調査リクエストのようです',
            SURVEY_ALREADY_ANSWERED: '既にこのアンケートに回答済みです',
            SURVEY_FILLED_THANKS: 'アンケートにご協力いただき、誠にありがとうございます。',
            "thanks_page_survey_thanks": "IT機器のインベントリ作成にご協力いただきありがとうございます。",
            "thanks_page_surveyAdminInfo": "ご質問やご提案がございましたら、ITシステムマネージャーの {{surveyAdminFullName}}（{{surveyAdminEmail}}）までご連絡ください。",
            "field_required_error": "この項目は必須です",
            "submit_error_dialog_title": "入力内容にエラーありました",
            "submit_error_dialog_content": "入力内容に誤りがあったため回答を送信できませんでした。回答を確認の上修正してください。",
            "reset_form_dialog_title": "回答内容をクリアしますか?",
            "reset_form_dialog_content": `“{{device_type}}の棚卸し”に入力した内容をすべてクリアしてよろしいですか?`,
            "error_message_content": "{{deviceAssetNumber}} の保存に失敗しました"
        }
    }
};

i18n
    .use(detector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        // lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option
        fallbackLng: "ja",
        supportedLngs: ["en", "ja"],
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;