import thanksImg from '../images/thanksImg.svg';
import '../global.css';
import Card from '@mui/material/Card';
import { useTranslation } from 'react-i18next';
import i18n from './i18n';
import constants from '../lib/constants';

function ThanksCard({
    errorMessageCode,
    surveyAdmin
}) {
    const { t } = useTranslation();
    return (
        <Card className='thanksPg' sx={{ boxShadow: 0, textAlign: 'center', fontSize: 14, alignItems: 'center' }} data-cy="message-panel">
            {
                i18n.language === constants.DEFAULT_LANGUAGE ? <img src={thanksImg} alt="thanksImg" className='thanksImg' data-cy="image"/> : ''
            }
            <h1 data-cy="message-title">{
                errorMessageCode ? t(errorMessageCode) : t('SURVEY_FILLED_THANKS')
            }
            </h1>
            <p data-cy="message-subtitle">{t('thanks_page_survey_thanks')} { surveyAdmin ? t('thanks_page_surveyAdminInfo', { surveyAdminEmail: surveyAdmin.email, surveyAdminFullName: surveyAdmin.first_name + ' ' + surveyAdmin.last_name }) : ''}</p>
            {/* <Link sx={{ mt: 4, display: 'inline-block', fontSize: 14, fontWeight: 'bold' }} href="javascript:window.close();" underline="none"> {'Close this page'} </Link> */}
        </Card>
    );
}

export default ThanksCard;
