import logo from './images/logo.svg';
import logoEng from './images/logo-en.svg';
import './global.css';
import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import ThanksPage from './pages/ThanksPage';
import SurveyPage from './pages/SurveyPage';
import SurveyPreviewPage from './pages/SurveyPreviewPage';
import LanguageSwitcher from './components/LanguageSwitcher';
import i18n from './components/i18n';
import { useEffect, useState } from 'react';
import { SnackbarProvider } from 'notistack';
import { SnackbarUtilsConfigurator } from './components/snackActions';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LanguageIcon from '@mui/icons-material/Language';
import pendoScript from './lib/Pendo/PendoScript';
import constants from './lib/constants';

function App() {
  const [language, setLanguage] = useState(i18n.language);
  const [orgName, setOrgName] = useState('Josys Corporation');

  useEffect(() => {
    pendoScript();
  }, []);

  return (
    <BrowserRouter>
      <div className="App">
        <AppBar position="static" className="header" color="transparent">
          <Container maxWidth="xl">
            <Toolbar disableGutters style={{ flexGrow: 1 }}>
              <img src={language === constants.DEFAULT_LANGUAGE ? logo : logoEng} alt="logo" className="logoImg" />{' '}
              <span style={{ flexGrow: 1 }}>{orgName} </span>
              <LanguageIcon edge="end"></LanguageIcon>
              <LanguageSwitcher language={language} setLanguage={setLanguage} />
            </Toolbar>
          </Container>
        </AppBar>

        <Container sx={{ mt: 5 }} maxWidth="lg">
          <SnackbarProvider
            maxSnack={5}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            classes={{
              containerRoot:
                'bottom-0 right-0 mb-52 md:mb-68 mr-8 lg:mr-80 z-99',
            }}
            data-cy="toast-message"
          >
            <SnackbarUtilsConfigurator />
            <Routes>
              <Route
                exact
                path="/survey/:randomId"
                element={<SurveyPage setLanguage={setLanguage} language={language} setOrgName={setOrgName} />}
              ></Route>
              <Route
                exact
                path="/survey-preview/:randomId"
                element={<SurveyPreviewPage setLanguage={setLanguage} language={language} />}
              ></Route>
              <Route path="/thanks" element={<ThanksPage />}></Route>
            </Routes>
          </SnackbarProvider>
        </Container>
      </div>
    </BrowserRouter>
  );
}

export default App;
